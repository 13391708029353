import React, { Component } from 'react';
import './Main.css';
import Countdown from '../images/炸彈.png'
import Money from '../images/錢.png'
import Smale from '../images/小公雞.png';
import Mfemale from '../images/中母雞.png';
import Bmale from '../images/大公雞.png';
import Eggs from '../images/雞蛋籃.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

class Main extends Component{
    constructor(props){
        super(props);
        this.state = {
            userstatus: {},
            round: {},
            showStatus: false,
            seasons: ['春', '夏', '秋', '冬']

        };
        this.Lstatus = this.Lstatus.bind(this)
      }

    Lstatus(){
        this.setState({
            showStatus: !this.state.showStatus
        })
    }

    Mstatus(){
        this.setState({
            showStatus: !this.state.showStatus
        })
    }

    Sstatus(){
        this.setState({
            showStatus: !this.state.showStatus
        })
    }

    close(){
        this.setState({
            showStatus: false,
        })
    }

    async componentDidMount(){
        await this.setState({
            userstatus: this.props.userstatus,
            round: this.props.round
        })
        console.log(this.state.userstatus)


        this.props.socket.on('USER_STATUS', ()=>{
        })

    }

    // feed(){
    //     this.props.socket.emit('FEED_REQ', {
    //         rid: this.state.round.rid,
    //     })
    // }


    render(){
        return(
            <div className="Main-wrapper">
                <div className="navbar">
                    <span>{this.state.seasons[this.state.round.currentSeason]}</span>
                    <span>{this.state.userstatus.name}</span>
                    <span><img className="main-money" src={Money} alt="" /> {this.state.userstatus.money}</span>
                    <span><img className="main-countdown" src={Countdown} alt="" /></span>
                    <span>販賣剩餘 6 次</span>
                </div>

                <div className="farm">
                    <img className="eggs" src={Eggs} alt=""  onClick={()=>{}}/>
                    <img className="Smale" src={Smale} alt="" onClick={()=>{this.Sstatus()}}/>
                    <img className="Mfemale" src={Mfemale} alt="" onClick={()=>{this.Mstatus()}}/>
                    <img className="Bmale" src={Bmale} alt=""  onClick={()=>{this.Lstatus()}}/>
                    <table className="chickhouse">
                        <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {
                    this.state.showStatus?
                        <div className="Status-list">
                            <div className="opacitybackground">
                                <div className="chickenlist">
                                <FontAwesomeIcon className="cross" icon={faTimes} onClick={()=>{this.close()}}/>
                                    <table className="status-box">
                                        <tbody>
                                            <tr>
                                                <td>性別</td>
                                                <td>大小</td>
                                                <td>施打疫苗</td>
                                                <td>傳染</td>
                                                <td onClick={() => {this.feed()}}>餵食</td>
                                                <td onClcik={() => {}}>牛刀</td>
                                                <td onClcik={() => {}}>交配</td>
                                            </tr>
                                            <tr>

                                                {

                                                }

                                                <td>Male</td>
                                                <td>Large</td>
                                                <td>是</td>
                                                <td>否</td>
                                                <td>餵食</td>
                                                <td>牛刀</td>
                                                <td>交配</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    :null
                }

            </div>
        )
    }
}

export default Main