import React, { Component } from 'react';
import './SelectedAction.css';
import Background from '../images/選擇行動背景圖.png'
import Feed from '../images/飼料.png'
import Sex from '../images/交配.png'
import Buy from '../images/購買.png'
import Sell from '../images/販賣.png'

class SelectedAction extends Component{
    constructor(props){
        super(props)
        this.state={
            userstatus: {
            }
        }
    }

    async componentDidMount(){
        await this.setState({
            userstatus: this.props.userstatus
        })
        console.log(this.state.userstatus.actions)
        setTimeout(() => {this.props.changeSection(9)}, 3000)
    }

    render(){
        return(
            <div className="SelectedAction-wrapper">
                {/* <img className="background" src={Background} alt="" /> */}
                {/* <div className="choose">您獲得了新動作</div> */}
                <div className="choose-box">您獲得了新動作</div>
                <br/>
                <img className="choose-action" src={Feed} alt="" />
                <img className="choose-action" src={Sex} alt="" />
                <img className="choose-action" src={Buy} alt="" />
                <img className="choose-action" src={Sell} alt="" />
            </div>
        )
    }
}

export default SelectedAction