import React, { Component } from 'react';
import './App.css';
import Name from './ui/Name';
import Room from  './ui/Room';
import Create from './ui/Create'
import Tutorial from './ui/Tutorial'
import Purchase from './ui/Purchase'
import Action from './ui/Action'
import Season from './ui/Season'
import Join from './ui/Join'
import SelectedAction from './ui/SelectedAction'
import Main from './ui/Main'
import openSocket from 'socket.io-client';
const socket = openSocket('https://nuk.noob.tw/');


class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      currentSection: 0,
      name: '',
      roomstatus: {},
      userstatus: {},
      round: {},
    };
    this.updateName = this.updateName.bind(this)
    this.changeSection = this.changeSection.bind(this)
    this.updateRoomID = this.updateRoomID.bind(this)
    this.updateAction = this.updateAction.bind(this)
    this.updateRound = this.updateRound.bind(this)
  }

  async changeSection(r){
    socket.off('USER_STATUS');
    await this.setState({currentSection: r})
  }

  updateName(inputName){
    this.setState({
      name: inputName,
      currentSection: 1,
    });
  }

  updateRoomID(ROOM_STATUS){
    this.setState({
      roomstatus: ROOM_STATUS,
    })
  }

  async updateAction(USER_STATUS){
    await this.setState({
      userstatus: USER_STATUS,
    })
    console.log(this.state.userstatus)
  }

  async updateRound(ROUND_START){
    await this.setState({
      round:  ROUND_START,
    })
  }

  render() {
    return (
      <div className="App">



      {this.state.currentSection === 0 ? <Name updateName={this.updateName} /> : null}
      {this.state.currentSection === 1 ? <Room socket={socket} updateRoomID={this.updateRoomID} name={this.state.name} changeSection={this.changeSection}/> : null}
      {this.state.currentSection === 2 ? <Create socket={socket} changeSection={this.changeSection} roomstatus={this.state.roomstatus} updateRoomID={this.updateRoomID} name={this.state.name}/> : null}
      {this.state.currentSection === 3 ? <Join socket={socket} changeSection={this.changeSection} name={this.state.name}/> : null}
      {this.state.currentSection === 4 ? <Tutorial changeSection={this.changeSection}/> : null}
      {this.state.currentSection === 5 ? <Purchase socket={socket} changeSection={this.changeSection} roomstatus={this.state.roomstatus}/> : null}
      {this.state.currentSection === 6 ? <Season changeSection={this.changeSection}/> : null}
      {this.state.currentSection === 7 ? <Action updateRound={this.updateRound} updateAction={this.updateAction} socket={socket} changeSection={this.changeSection} roomstatus={this.state.roomstatus}/> : null}
      {this.state.currentSection === 8 ? <SelectedAction updateAction={this.updateAction} userstatus={this.state.userstatus} socket={socket} changeSection={this.changeSection}/> : null}
      {this.state.currentSection === 9 ? <Main round={this.state.round} socket={socket} userstatus={this.state.userstatus} changeSection={this.changeSection}/> : null}
      </div>
    );

  }
}


export default App;
